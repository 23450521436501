body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  position: absolute;
  top: 1.2rem;
  left: 1rem;
  color: #281414;
  z-index: 10;
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
}

.locate {
  position: fixed;
  top: .3rem;
  left: 21rem;
  background: none;
  border: none;
  z-index: 10;
  cursor:'pointer';
}
.locate img {
  width: 50px;
  cursor: pointer;
}

img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px
}

video {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px
}

.fullscreen-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
